<template>
  <tr v-if="info">
    <td class="w-30">
      <b-dropdown toggle-tag="button" toggle-class="no-border" no-caret>
        <template #button-content><i class="fa-regular fa-ellipsis"></i></template>
        <b-dropdown-item  
          v-if="info.status != 'RECALLED'"
          size="sm"
          class="btn-fill mt-2"
          @click="resend"
          >{{ $t("RESEND_NOTIFICATION") }}
        </b-dropdown-item>        
        <b-dropdown-item @click="show = true">{{
          $t("MORE_INFORMATION")
        }}</b-dropdown-item>
        <b-dropdown-divider class="mt-2" v-if="!isMeeting && (item.status != 'NOT_VIEWED' && item.status != 'RECALLED')"></b-dropdown-divider>        
        <b-dropdown-item
          v-if="!isMeeting && (info.status == 'NOT_VIEWED' || info.status == 'SENT_WITHOUT_NOTIFICATION')"
          variant="danger"
          size="sm"
          class="btn-fill mt-2"
          @click="recall"
          >{{ $t("RECALL") }}</b-dropdown-item
        >
        <b-dropdown-divider class="mt-2" v-if="!isMeeting && (info.status != 'NOT_VIEWED' && info.status != 'SENT_WITHOUT_NOTIFICATION')"></b-dropdown-divider>      
        <b-dropdown-item        
        v-if="!isMeeting && (info.status != 'NOT_VIEWED' && info.status != 'SENT_WITHOUT_NOTIFICATION')"
        variant="danger"
        disabled
        link-class="red"
        size="sm"
        class="btn-fill mt-2"
        >{{ $t("CANNOT_RECALL") }})</b-dropdown-item
      >
      </b-dropdown>
    </td>
    <td class="w-30"><MessageStatus :status="info.status"></MessageStatus></td>
    <td class="w-100">
      <ContactInformation
        no-icon
        :user_uuid="user_uuid"
        :functionbox_uuid="functionbox_uuid"
        :identifier="info.email"
      ></ContactInformation>
    </td>
    <b-sidebar
      id="message-securelink"
      v-model="show"
      right
      no-slide
      shadow
      lazy
      backdrop
    >
      <SecureLinkInformation
        no-icon
        :contactInfo="contactInfo"
        :functionbox_uuid="functionbox_uuid"
        :user_uuid="user_uuid"
        :response="response"
        @RECALLED="RECALLED"
        @SENT="SENT"
        :isMeeting="isMeeting"
        :index="index"
        :item="info"
      ></SecureLinkInformation>
    </b-sidebar>
  </tr>
</template>
<script>
import ContactInformation from "../ContactInformation";
import MessageStatus from "../MessageStatus";
import SecureLinkInformation from "./SecureLinkInformation";
export default {
  components: { MessageStatus, SecureLinkInformation, ContactInformation },
  props: {
    message: {
      default: null,
      type: Object,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    creator: {
      default: false,
      type: Boolean,
    },
    islast: {
      default: false,
      type: Boolean,
    },
    index: {
      default: 0,
      type: Number,
    },
    response: {
      default: 0,
      type: Number,
    },
    item: {
      default: null,
      type: Object,
    },
    status: {
      default: "",
      type: String,
    },
    isMeeting: {
      type: Boolean,
      default: false
    }
  }, 
  data() {
    return {
      showAddParticipants: false,
      contactInfo: null,
      show: false,
      info: this.item,
      showDialog: false,
      viewed: false,
      viewed_date: "",
    };
  },
  methods: {
    SENT(){
      this.info.sent = 1;
      this.info.status = 'NOT_VIEWED';
    },
    changeShowParticipants(val){
      this.showAddParticipants = val;
    },
    onShow: function () {
      this.$root.$emit("bv::hide::popover");
    },
    onClose: function () {
      this.showDialog = false;
    },
    RECALLED(){
      this.info.status = 'RECALLED';
      this.info.recalled = 1;
    },
    recall: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(self.user.hostname + "/message/link-recall", {
                link_id: self.info.id,
              })
              .then(function (response) {
                self.info.recalled = 1;
                self.info.status = 'RECALLED';
                self.loading = false;
                self.info.recalled_date = response.data.recalled_date;
                self.$noty.info(self.$t("SAVED"));
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    resend: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(self.user.hostname + "/message/link-resend", {
                link_id: self.info.id,
              })
              .then(function () {
                self.loading = false;
                self.info.status = 'NOT_VIEWED';
                self.info.sent = 1;
                self.$noty.info(self.$t("SENT"));
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    showRecall(){
      return this.info.status == 'NOT_VIEWED' || this.info.status == 'SENT_WITHOUT_NOTIFICATION';
    },
    showStatus() {
      if (this.info.status == 'RECALLED') {
        return this.$t("RECALLED");
      }
      if (this.info.status == 'VIEWED') {
        return this.$t("VIEWED");
      }
      if (this.info.status == 'RESPONDED') {
        return this.$t("RESPONDED");
      }
      if(this.info.status == 'SENT_WITHOUT_NOTIFICATION')
      {
        return this.$t("NOT_SENT");
      }
      if(this.info.status == 'NOT_VIEWED')
      {
        return this.$t("NOT_VIEWED");
      }
      return "";
    },
  },
  mounted() {},
};
</script>
<style>
</style>