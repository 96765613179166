<template>
  <section v-if="message">
    <hr v-if="!isCreator" class="pb-0 mb-0 mb-2 mt-2" />
    <span v-if="!isCreator && recipientsNoCreator.length != 0">
      <span
        v-for="(item, index) in recipientsNoCreator"
        :key="index + '-recipient'"
        ><Recipient
          :index="index"
          :functionbox_uuid="functionbox_uuid"
          :isMeeting="IsMeeting(message.message_type)"
          :user_uuid="user_uuid"
          :item="item"
        ></Recipient
      ></span>
    </span>
    <table
      class="table table-sm table-menu"
      v-if="isCreator && !isConversation"
    >
      <span v-if="participantsNoCreator.length != 0">
        <Participant
          v-for="(item, index) in participantsNoCreator"
          :key="index + '-participant'"
          :message_uuid="message.message_uuid"
          :index="index"
          :item="item"
          :creator="isCreator"
          :isMeeting="IsMeeting(message.message_type)"
          :user_uuid="user_uuid"
          :functionbox_uuid="functionbox_uuid"
        ></Participant>
      </span>
      <span v-if="externalFilter.length != 0">
        <SecureLink
          v-for="(item, index) in externalFilter"
          :key="index + '-external'"
          :message="message"
          :response="message.settings.require_response"
          :index="index"
          :item="item"
          :isMeeting="IsMeeting(message.message_type)"
          :creator="isCreator"
          :user_uuid="user_uuid"
          :functionbox_uuid="functionbox_uuid"
        ></SecureLink>
      </span>
      <span v-if="hasExternalSystemParticipants">
        <ExternalParticipant
          v-for="(item, index) in external_system_participants"
          :key="index + '-external_system_participant'"
          class="ms-fontSize-12"
          :message_uuid="message.message_uuid"
          :index="index"
          :isMeeting="IsMeeting(message.message_type)"
          :item="item"
          :creator="isCreator"
          :user_uuid="user_uuid"
          :functionbox_uuid="functionbox_uuid"
        ></ExternalParticipant>
      </span>
    </table>
  </section>
</template>
<script>
import Recipient from "./Recipient";
import Participant from "./Participant";
import ExternalParticipant from "./ExternalParticipant";
import SecureLink from "./SecureLink";
export default {
  name: "MessageInformation",
  props: {
    message: {
      default: null,
      type: Object,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    showRecalled: {
      default: false,
      type: Boolean,
    },
    isCreator: {
      default: false,
      type: Boolean,
    },
    participants: {
      type: Array,
      default: () => [],
    },
    external_system_participants: {
      type: Array,
      default: () => [],
    },
    external: {
      type: Array,
      default: () => [],
    },
    isConversation: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  components: {
    Participant,
    SecureLink,
    ExternalParticipant,
    Recipient,
  },
  data() {
    return {
      showAddParticipants: false,
      response_to_message_uuid: "",
      last_response_uuid: "",
      responses_after: 0,
      responses_before: 0,
      showAddConversation: false,
      conversation_uuid: "",
      showAllResponse: false,
      loa_error: 0,
      show: false,
      showFunctionboxLog: false,
      showDelegatedAccountLog: false,
      opened: false,
      error: false,
      loading: true,
      email: "",
      errorMessage: "",
    };
  },
  methods: {
    checkLastParticipant(index) {
      if (this.external.length != 0) {
        return false;
      } else {
        return this.participantsNoCreator.length - 1 == index;
      }
    },
    checkLastRecipient(index) {
      if (this.message.recipients == undefined) {
        return true;
      }
      if (this.message.recipients.length == 0) {
        return false;
      } else {
        return this.recipientsNoCreator.length - 1 == index;
      }
    },
    async updateUser() {
      await this.$store.dispatch("user/fetchUser");
    },
  },
  computed: {
    hasExternalSystemParticipants() {
      return (
        this.external_system_participant != null &&
        this.external_system_participant.length != 0
      );
    },
    externalFilter() {
      if (this.external == null) {
        return [];
      }
      return this.external.filter(
        (c) =>
          (!this.showRecalled && c.status != "RECALLED") || this.showRecalled
      );
    },
    participantsNoCreator() {
      let creatorEmail = this.message.creator.email;
      return this.participants.filter((c) => c.email != creatorEmail);
    },
    recipientsNoCreator() {
      let self = this;
      return this.message.recipients.filter(
        (c) => c.email != self.message.creator.email
      );
    },
  },
  mounted: function () {},
};
</script>
<style></style>
