var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "tr",
        [
          _c(
            "td",
            { staticClass: "w-30" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    "toggle-tag": "button",
                    "toggle-class": "no-border",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "fa-regular fa-ellipsis" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2574289274
                  ),
                },
                [
                  _vm.info.status != "RECALLED"
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "btn-fill mt-2",
                          attrs: { size: "sm" },
                          on: { click: _vm.resend },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("RESEND_NOTIFICATION")) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.show = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MORE_INFORMATION")))]
                  ),
                  !_vm.isMeeting &&
                  _vm.item.status != "NOT_VIEWED" &&
                  _vm.item.status != "RECALLED"
                    ? _c("b-dropdown-divider", { staticClass: "mt-2" })
                    : _vm._e(),
                  !_vm.isMeeting &&
                  (_vm.info.status == "NOT_VIEWED" ||
                    _vm.info.status == "SENT_WITHOUT_NOTIFICATION")
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "btn-fill mt-2",
                          attrs: { variant: "danger", size: "sm" },
                          on: { click: _vm.recall },
                        },
                        [_vm._v(_vm._s(_vm.$t("RECALL")))]
                      )
                    : _vm._e(),
                  !_vm.isMeeting &&
                  _vm.info.status != "NOT_VIEWED" &&
                  _vm.info.status != "SENT_WITHOUT_NOTIFICATION"
                    ? _c("b-dropdown-divider", { staticClass: "mt-2" })
                    : _vm._e(),
                  !_vm.isMeeting &&
                  _vm.info.status != "NOT_VIEWED" &&
                  _vm.info.status != "SENT_WITHOUT_NOTIFICATION"
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "btn-fill mt-2",
                          attrs: {
                            variant: "danger",
                            disabled: "",
                            "link-class": "red",
                            size: "sm",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("CANNOT_RECALL")) + ")")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "td",
            { staticClass: "w-30" },
            [_c("MessageStatus", { attrs: { status: _vm.info.status } })],
            1
          ),
          _c(
            "td",
            { staticClass: "w-100" },
            [
              _c("ContactInformation", {
                attrs: {
                  "no-icon": "",
                  user_uuid: _vm.user_uuid,
                  functionbox_uuid: _vm.functionbox_uuid,
                  identifier: _vm.info.email,
                },
              }),
            ],
            1
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "message-securelink",
                right: "",
                "no-slide": "",
                shadow: "",
                lazy: "",
                backdrop: "",
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c("SecureLinkInformation", {
                attrs: {
                  "no-icon": "",
                  contactInfo: _vm.contactInfo,
                  functionbox_uuid: _vm.functionbox_uuid,
                  user_uuid: _vm.user_uuid,
                  response: _vm.response,
                  isMeeting: _vm.isMeeting,
                  index: _vm.index,
                  item: _vm.info,
                },
                on: { RECALLED: _vm.RECALLED, SENT: _vm.SENT },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }