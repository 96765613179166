var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "view-content" },
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _vm.opened
          ? _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _vm.showNewMessages
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "no-border",
                            on: { click: _vm.showConversation },
                          },
                          [
                            _c("i", {
                              staticClass: "fal fa-chevron-double-up",
                            }),
                            _vm._v(
                              "  \n          " +
                                _vm._s(_vm.responses_after) +
                                " " +
                                _vm._s(_vm.$t("CONVERSATION_NEW_MESSAGES")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.SdkConfidential
                  ? _c(
                      "b-alert",
                      {
                        staticClass: "mb-1",
                        attrs: { show: "", variant: "warning" },
                      },
                      [_vm._v(_vm._s(_vm.$t("SDK.IsConfidential")))]
                    )
                  : _vm._e(),
                !_vm.functionbox_uuid && _vm.showArchived
                  ? _c(
                      "b-alert",
                      {
                        staticClass: "mb-1",
                        attrs: { show: "", variant: "secondary" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("CLOSED_CONVERSATION")) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.functionbox_uuid && _vm.showArchived
                  ? _c(
                      "b-alert",
                      {
                        staticClass: "mb-1",
                        attrs: { show: "", variant: "secondary" },
                      },
                      [_vm._v(_vm._s(_vm.$t("CLOSED_ISSUE")) + "\n      ")]
                    )
                  : _vm._e(),
                _vm.functionbox_uuid &&
                _vm.claimedBy &&
                _vm.claimedBy.email == _vm.user.information.email
                  ? _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("CLAIMED_BY")) +
                          " " +
                          _vm._s(_vm.$t("ME"))
                      ),
                    ])
                  : _vm._e(),
                _vm.functionbox_uuid &&
                _vm.claimedBy &&
                _vm.claimedBy.email != _vm.user.information.email
                  ? _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("CLAIMED_BY")) +
                          " " +
                          _vm._s(_vm.claimedBy.email)
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _vm.showCloseBtn
                      ? _c(
                          "b-button",
                          {
                            staticClass: "no-border",
                            attrs: { size: "sm" },
                            on: { click: _vm.closeMessage },
                          },
                          [
                            _c("i", { staticClass: "fal fa-times" }),
                            _c("span", { staticClass: "hidden-mobile" }, [
                              _vm._v(" " + _vm._s(_vm.$t("CLOSE"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.isConversation
                      ? _c(
                          "b-button",
                          {
                            staticClass: "no-border d-print-none",
                            attrs: { size: "sm" },
                            on: { click: _vm.gotoMessage },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("GOTO_MESSAGE")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isConversation
                      ? _c(
                          "b-button",
                          {
                            staticClass: "no-border",
                            attrs: { size: "sm" },
                            on: { click: _vm.getInformation },
                          },
                          [
                            _c("i", { staticClass: "fal fa-sync" }),
                            _c("span", { staticClass: "hidden-mobile" }, [
                              _vm._v(" " + _vm._s(_vm.$t("UPDATE"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.IsMeeting(_vm.message.message_type) && _vm.isCreator
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              size: "sm",
                              id: "dropdown-change-meeting",
                              text: _vm.$t("CHANGE"),
                              "toggle-class": "no-border",
                            },
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.changeMeetingInformation.click()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("CHANGE_MEETING_INFORMATION"))
                                ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.changeMeetingFiles.click()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("CHANGE_MEETING_FILES")))]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.changeMeetingTime.click()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("CHANGE_TIME")))]
                            ),
                            _vm.canRemove &&
                            _vm.IsMeeting(_vm.message.message_type) &&
                            _vm.isCreator
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.removeMeeting } },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("CANCEL_MEETING"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "ChangeMeeting",
                      {
                        attrs: {
                          recurrence: _vm.recurrence,
                          message: _vm.message,
                          "is-change": "",
                          startMeeting: _vm.startDate,
                          endMeeting: _vm.endDate,
                        },
                        on: {
                          ChangeShow: _vm.UpdateInformation,
                          Changed: _vm.UpdateInformation,
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            ref: "changeMeetingInformation",
                            staticClass: "hidden-mobile hidden-not-mobile",
                            attrs: { size: "sm" },
                          },
                          [
                            _c("i", { staticClass: "fal fa-pen-to-square" }),
                            _c("span", { staticClass: "hidden-mobile" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("CHANGE_MEETING_INFORMATION"))
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.IsMeeting(_vm.message.message_type) && _vm.isCreator
                      ? _c(
                          "ChangeMeeting",
                          {
                            attrs: {
                              "change-attachments": "",
                              recurrence: _vm.recurrence,
                              message: _vm.message,
                              startMeeting: _vm.startDate,
                              endMeeting: _vm.endDate,
                            },
                            on: {
                              ChangeShow: _vm.UpdateInformation,
                              Changed: _vm.UpdateInformation,
                            },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                ref: "changeMeetingFiles",
                                staticClass: "hidden-mobile hidden-not-mobile",
                                attrs: { size: "sm" },
                              },
                              [
                                _vm._v("\n          >"),
                                _c("i", {
                                  staticClass: "fal fa-pen-to-square",
                                }),
                                _c("span", { staticClass: "hidden-mobile" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("CHANGE_MEETING_FILES"))
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.IsMeeting(_vm.message.message_type) && _vm.isCreator
                      ? _c(
                          "ChangeMeeting",
                          {
                            attrs: {
                              "change-time": "",
                              recurrence: _vm.recurrence,
                              message: _vm.message,
                              startMeeting: _vm.startDate,
                              endMeeting: _vm.endDate,
                            },
                            on: {
                              ChangeShow: _vm.UpdateInformation,
                              Changed: _vm.UpdateInformation,
                            },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                ref: "changeMeetingTime",
                                staticClass: "hidden-mobile hidden-not-mobile",
                                attrs: { size: "sm" },
                              },
                              [
                                _vm._v("\n          >"),
                                _c("i", {
                                  staticClass: "fal fa-pen-to-square",
                                }),
                                _c("span", { staticClass: "hidden-mobile" }, [
                                  _vm._v(" " + _vm._s(_vm.$t("CHANGE_TIME"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showComplete
                      ? _c(
                          "AddMessageReply",
                          {
                            attrs: {
                              message: _vm.message,
                              "reply-complete": "",
                              functionbox_uuid: _vm.functionbox_uuid,
                              user_uuid: _vm.user_uuid,
                            },
                            on: { Changed: _vm.UpdateInformation },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "no-border",
                                attrs: { size: "sm" },
                              },
                              [
                                _c("i", { staticClass: "fal fa-reply-all" }),
                                _c("span", { staticClass: "hidden-mobile" }, [
                                  _vm._v(" "),
                                  _vm.isCreatorParticipant
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("RESPOND")) + "AA"
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.isCreatorParticipant
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("COMPLETE"))),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showRespond
                      ? _c(
                          "AddMessageReply",
                          {
                            attrs: {
                              message: _vm.message,
                              reply: "",
                              functionbox_uuid: _vm.functionbox_uuid,
                              user_uuid: _vm.user_uuid,
                            },
                            on: { Changed: _vm.UpdateInformation },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "no-border",
                                attrs: { size: "sm" },
                              },
                              [
                                _c("i", { staticClass: "fal fa-reply" }),
                                _c("span", { staticClass: "hidden-mobile" }, [
                                  _vm._v(" " + _vm._s(_vm.$t("RESPOND"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showRespondAll
                      ? _c(
                          "AddMessageReply",
                          {
                            attrs: {
                              message: _vm.message,
                              "reply-all": "",
                              functionbox_uuid: _vm.functionbox_uuid,
                              user_uuid: _vm.user_uuid,
                            },
                            on: { Changed: _vm.UpdateInformation },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "no-border",
                                attrs: { size: "sm" },
                              },
                              [
                                _c("i", { staticClass: "fal fa-reply-all" }),
                                _c("span", { staticClass: "hidden-mobile" }, [
                                  _vm._v(" " + _vm._s(_vm.$t("RESPOND_ALL"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showForward
                      ? _c(
                          "AddMessageForward",
                          {
                            attrs: {
                              message: _vm.message,
                              functionbox_uuid: _vm.functionbox_uuid,
                              user_uuid: _vm.user_uuid,
                            },
                            on: { Changed: _vm.UpdateInformation },
                          },
                          [
                            _c("i", { staticClass: "fal fa-share" }),
                            _c("span", { staticClass: "hidden-mobile" }, [
                              _vm._v(" " + _vm._s(_vm.$t("FORWARD"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.message && !_vm.isConversation
                      ? _c(
                          "b-dropdown",
                          {
                            attrs: {
                              size: "sm",
                              id: "dropdown-1",
                              text: _vm.$t("MANAGE"),
                              "toggle-class": "no-border",
                            },
                          },
                          [
                            _vm.showClaimBtn
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.claim } },
                                  [_vm._v(_vm._s(_vm.$t("CLAIM")))]
                                )
                              : _vm._e(),
                            _vm.showRemoveClaimBtn
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.noClaim } },
                                  [_vm._v(_vm._s(_vm.$t("REMOVE_CLAIM")))]
                                )
                              : _vm._e(),
                            _vm.showCloseConversationBtn
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.archive } },
                                  [_vm._v(_vm._s(_vm.$t("CLOSE_CONVERSATION")))]
                                )
                              : _vm._e(),
                            _vm.showNoArchiveBtn
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.noArchive } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("REOPEN_CONVERSATION"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showCloseIssueBtn
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.closeIssue } },
                                  [_vm._v(_vm._s(_vm.$t("CLOSE_ISSUE")))]
                                )
                              : _vm._e(),
                            _vm.showOpenIssueBtn
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.noArchive } },
                                  [_vm._v(_vm._s(_vm.$t("OPEN_ISSUE")))]
                                )
                              : _vm._e(),
                            _vm.IsMessage(_vm.message.message_type) &&
                            (_vm.showClaimBtn ||
                              _vm.showRemoveClaimBtn ||
                              _vm.showCloseConversationBtn ||
                              _vm.showNoArchiveBtn ||
                              _vm.showCloseIssueBtn ||
                              _vm.showOpenIssueBtn)
                              ? _c("b-dropdown-divider")
                              : _vm._e(),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "no-border",
                                attrs: {
                                  href: _vm.direktLink,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("OPEN_NEW_TAB")) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              { on: { click: _vm.printBtn } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("PRINT")) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm.functionbox_uuid && _vm.canRemove
                              ? _c("b-dropdown-divider")
                              : _vm._e(),
                            _vm.canRemove &&
                            !_vm.IsMeeting(_vm.message.message_type)
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.removeMessage } },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("DELETE"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { id: "printMe" } },
                  [
                    _c(
                      "b-container",
                      {
                        staticClass: "ml-0 mr-0 pl-0 pr-0",
                        attrs: { fluid: "" },
                      },
                      [
                        _c(
                          "b-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "ml-0 pl-0 pr-2",
                                attrs: { md: _vm.mdcols, sm: "12" },
                              },
                              [
                                _c(
                                  "b-card",
                                  {
                                    ref: "printBody",
                                    staticClass: "mt-2 card-message",
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.isConversation
                                          ? {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-break",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "icon mr-2",
                                                        attrs: {
                                                          src: _vm.iconImage,
                                                          alt: _vm.message
                                                            .subject,
                                                        },
                                                      }),
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.message.subject
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                        {
                                          key: "footer",
                                          fn: function () {
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "d-print-none clickable",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.show = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ml-2 fal fa-info-circle",
                                                  }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(_vm.$t("DETAILS"))
                                                  ),
                                                ]
                                              ),
                                              _vm.functionbox_uuid
                                                ? _c(
                                                    "FunctionBoxAccounts",
                                                    {
                                                      attrs: {
                                                        message_uuid:
                                                          _vm.message_uuid,
                                                        functionbox_uuid:
                                                          _vm.functionbox_uuid,
                                                        functionbox_accounts:
                                                          _vm.functionbox_accounts,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "ml-2 fal fa-info-circle",
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FUNCTIONBOX_USERS"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.functionbox_uuid
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass: "clickable",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showFunctionboxLog = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "ml-2 fal fa-info-circle",
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(_vm.$t("LOGG"))
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.delegated_accounts.length != 0
                                                ? _c(
                                                    "DelegatedAccounts",
                                                    {
                                                      attrs: {
                                                        message_uuid:
                                                          _vm.message_uuid,
                                                        user_uuid:
                                                          _vm.user_uuid,
                                                        delegated_accounts:
                                                          _vm.delegated_accounts,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "ml-2 fal fa-info-circle",
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FUNCTIONBOX_USERS"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.delegated_accounts.length != 0
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass: "clickable",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showDelegatedAccountLog = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "ml-2 fal fa-info-circle",
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(_vm.$t("LOGG"))
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("UserInfo", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        image: "/img/default-avatar.png",
                                        primary: _vm.createdName,
                                        secondary: _vm.message.creator.email,
                                        optional: _vm.createdTime,
                                      },
                                    }),
                                    _vm.functionBoxCreatedBy != ""
                                      ? _c("small", [
                                          _vm._v(
                                            _vm._s(_vm.$t("CREATED_BY")) +
                                              " " +
                                              _vm._s(_vm.functionBoxCreatedBy)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.delegatedCreatedBy != ""
                                      ? _c("small", [
                                          _vm._v(
                                            _vm._s(_vm.$t("CREATED_BY")) +
                                              " " +
                                              _vm._s(_vm.delegatedCreatedBy)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.showRecipients && !_vm.isCreator
                                      ? _c("Recipients", {
                                          attrs: {
                                            message: _vm.message,
                                            isCreator: false,
                                            showRecalled: _vm.showRecalled,
                                            participants: _vm.participants,
                                            external_system_participants:
                                              _vm.external_system_participants,
                                            external: _vm.external,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                                    _vm.regular_message
                                      ? _c("RegularMessage", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type == "fax-message"
                                      ? _c("FaxMessage", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                            external_system_participants:
                                              _vm.external_system_participants,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type == "sdk-message"
                                      ? _c("SdkMessage", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                            external_system_participants:
                                              _vm.external_system_participants,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type == "system-message"
                                      ? _c("SystemMessage", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type == "teams-meeting"
                                      ? _c("TeamsMeeting", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            startMeeting: _vm.startDate,
                                            endMeeting: _vm.endDate,
                                            recurrence: _vm.recurrence,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type == "nuiteq-meeting"
                                      ? _c("NuiteqMeeting", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            startMeeting: _vm.startDate,
                                            endMeeting: _vm.endDate,
                                            recurrence: _vm.recurrence,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type == "jitsi-meeting"
                                      ? _c("JitsiMeeting", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            startMeeting: _vm.startDate,
                                            endMeeting: _vm.endDate,
                                            recurrence: _vm.recurrence,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.message.message_type ==
                                    "jitsi-secure-meeting"
                                      ? _c("JitsiSecureMeeting", {
                                          attrs: {
                                            message: _vm.message,
                                            user_uuid: _vm.user_uuid,
                                            startMeeting: _vm.startDate,
                                            endMeeting: _vm.endDate,
                                            recurrence: _vm.recurrence,
                                            filestorage: _vm.filestorage,
                                            functionbox_uuid:
                                              _vm.functionbox_uuid,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.ParticipantNoCreatorCount != 0 &&
                            _vm.showRecipients &&
                            !_vm.isConversation &&
                            _vm.isCreator
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "mr-0 pr-0",
                                    attrs: { md: "6", sm: "12" },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass:
                                          "mt-2 card-message card-titleform",
                                        attrs: { "no-body": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("RECIPIENTS")
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3609133430
                                        ),
                                      },
                                      [
                                        _c("Recipients", {
                                          attrs: {
                                            message: _vm.message,
                                            isCreator: _vm.isCreator,
                                            showRecalled: _vm.showRecalled,
                                            participants: _vm.participants,
                                            external_system_participants:
                                              _vm.external_system_participants,
                                            external: _vm.external,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    !_vm.IsMeeting(_vm.message.message_type) &&
                                    _vm.isCreator
                                      ? _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              id: "showRecalled",
                                              switch: "",
                                              name: "checkbox-1",
                                              value: true,
                                              "unchecked-value": false,
                                            },
                                            model: {
                                              value: _vm.showRecalled,
                                              callback: function ($$v) {
                                                _vm.showRecalled = $$v
                                              },
                                              expression: "showRecalled",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("SHOW_RECALLED")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-sidebar",
                      {
                        attrs: {
                          id: "message-information",
                          lazy: "",
                          right: "",
                          "no-slide": "",
                          shadow: "",
                          backdrop: "",
                        },
                        model: {
                          value: _vm.show,
                          callback: function ($$v) {
                            _vm.show = $$v
                          },
                          expression: "show",
                        },
                      },
                      [
                        _c("div", { staticClass: "px-3 py-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("MESSAGE_DETAILS")) +
                              "\n\n            "
                          ),
                          _c("hr", { staticClass: "pb-0 mb-0 mt-2 mb-3" }),
                          _c("h6", { staticClass: "mb-0 no-uppercase" }, [
                            _vm._v(_vm._s(_vm.$t("MESSAGE_UUID"))),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.message.message_uuid) +
                              "\n\n            "
                          ),
                          _vm.message.creator.name != ""
                            ? _c("div", [
                                _c(
                                  "h6",
                                  { staticClass: "mt-3 mb-0 no-uppercase" },
                                  [_vm._v(_vm._s(_vm.$t("CREATOR_UUID")))]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.message.creator.user_uuid) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm.functionbox_uuid
                            ? _c("div", [
                                _c(
                                  "h6",
                                  { staticClass: "mt-3 mb-0 no-uppercase" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("RECIPIENT")) +
                                        " " +
                                        _vm._s(_vm.$t("FUNCTIONBOX.TITLE")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.functionbox_uuid) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("MESSAGE_VERSION")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.message.version) +
                              "\n\n            "
                          ),
                          !_vm.responseType
                            ? _c("div", [
                                _c(
                                  "h6",
                                  { staticClass: "mt-3 mb-0 no-uppercase" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("LOA.TITLE_PARTICIPANT")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "fal fa-info-square",
                                  attrs: {
                                    title: _vm.$t(
                                      "LOA.INFO." +
                                        _vm.message.settings.loa_level
                                    ),
                                  },
                                }),
                                _vm._v(
                                  "\n              LOA" +
                                    _vm._s(_vm.message.settings.loa_level) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _c("h6", { staticClass: "mt-3 mb-0 no-uppercase" }, [
                            _vm._v(_vm._s(_vm.$t("TYPE"))),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "MESSAGES.TYPE." + _vm.message.message_type
                                )
                              ) +
                              "\n\n            "
                          ),
                          _vm.message.settings.require_response
                            ? _c(
                                "h6",
                                { staticClass: "mt-3 mb-0 no-uppercase" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("RESPONSE")) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.message.settings.require_response
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("MESSAGES.REQUESTED_RESPONSE"))
                                ),
                              ])
                            : _vm._e(),
                          _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                        ]),
                      ]
                    ),
                    _c(
                      "b-sidebar",
                      {
                        attrs: {
                          id: "message-eventlog",
                          lazy: "",
                          right: "",
                          "no-slide": "",
                          shadow: "",
                          backdrop: "",
                        },
                        model: {
                          value: _vm.showFunctionboxLog,
                          callback: function ($$v) {
                            _vm.showFunctionboxLog = $$v
                          },
                          expression: "showFunctionboxLog",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "px-3 py-2" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("LOGG")) +
                                "\n\n            "
                            ),
                            _c("hr", { staticClass: "pb-0 mb-0 mt-2 mb-3" }),
                            _vm.functionbox_uuid
                              ? _c("EventLog", {
                                  attrs: {
                                    message_uuid: _vm.message_uuid,
                                    type: "message-functionbox",
                                    functionbox_uuid: _vm.functionbox_uuid,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-sidebar",
                      {
                        attrs: {
                          id: "message-eventlog-useruuid",
                          lazy: "",
                          right: "",
                          "no-slide": "",
                          shadow: "",
                          backdrop: "",
                        },
                        model: {
                          value: _vm.showDelegatedAccountLog,
                          callback: function ($$v) {
                            _vm.showDelegatedAccountLog = $$v
                          },
                          expression: "showDelegatedAccountLog",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "px-3 py-2" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("LOGG")) +
                                "\n\n            "
                            ),
                            _c("hr", { staticClass: "pb-0 mb-0 mt-2 mb-3" }),
                            _vm.delegated_accounts.length != 0
                              ? _c("EventLog", {
                                  attrs: {
                                    message_uuid: _vm.message_uuid,
                                    type: "message-delegated-account",
                                    user_uuid: _vm.user.information.user_uuid,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          "dialog-class": _vm.teams.theme,
                          "ok-title": _vm.$t("REMOVE"),
                          "cancel-title": _vm.$t("CANCEL"),
                          "hide-header": "",
                          "ok-variant": "danger",
                          centered: "",
                          id: "removeMeetingModal",
                        },
                        on: { ok: _vm.RemoveRecurringMeeting },
                        model: {
                          value: _vm.showRemoveMeeting,
                          callback: function ($$v) {
                            _vm.showRemoveMeeting = $$v
                          },
                          expression: "showRemoveMeeting",
                        },
                      },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  name: "remove-recurring-all",
                                  value: "SINGLE",
                                },
                                model: {
                                  value: _vm.selectRemoveRecurringMeeting,
                                  callback: function ($$v) {
                                    _vm.selectRemoveRecurringMeeting = $$v
                                  },
                                  expression: "selectRemoveRecurringMeeting",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("REMOVE_RECURRENCE_SINGLE"))
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  name: "remove-recurring-all",
                                  value: "MULTIPLE",
                                },
                                model: {
                                  value: _vm.selectRemoveRecurringMeeting,
                                  callback: function ($$v) {
                                    _vm.selectRemoveRecurringMeeting = $$v
                                  },
                                  expression: "selectRemoveRecurringMeeting",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("REMOVE_RECURRENCE_MULTIPLE"))
                                ),
                              ]
                            ),
                            _c(
                              "b-form-radio",
                              {
                                attrs: {
                                  name: "remove-recurring-all",
                                  value: "ALL",
                                },
                                model: {
                                  value: _vm.selectRemoveRecurringMeeting,
                                  callback: function ($$v) {
                                    _vm.selectRemoveRecurringMeeting = $$v
                                  },
                                  expression: "selectRemoveRecurringMeeting",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("REMOVE_RECURRENCE_ALL")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("iframe", {
                  attrs: {
                    name: "print_frame",
                    width: "0",
                    height: "0",
                    frameborder: "0",
                    src: "about:blank",
                  },
                }),
                _vm.showOldMessages
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "text-center mb-2" },
                        [
                          _vm.responses_before > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "no-border",
                                  on: { click: _vm.showConversation },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fal fa-chevron-double-down",
                                  }),
                                  _vm._v(
                                    "  \n            " +
                                      _vm._s(_vm.responses_before) +
                                      " " +
                                      _vm._s(
                                        _vm.$t("CONVERSATION_OLDER_MESSAGES")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.error
          ? _c(
              "div",
              [
                _vm.showCloseBtn
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "no-border",
                        attrs: { title: _vm.$t("CLOSE") },
                        on: { click: _vm.closeMessage },
                      },
                      [
                        _c("i", { staticClass: "fal fa-times" }),
                        _c("span", { staticClass: "hidden-mobile" }, [
                          _vm._v(" " + _vm._s(_vm.$t("CLOSE"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.isConversation
                  ? _c(
                      "b-button",
                      {
                        staticClass: "no-border",
                        on: { click: _vm.getInformation },
                      },
                      [
                        _c("i", { staticClass: "fal fa-sync" }),
                        _c("span", { staticClass: "hidden-mobile" }, [
                          _vm._v(" " + _vm._s(_vm.$t("UPDATE"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.isConversation && _vm.errorMessage != "ERROR.RECALLED"
                  ? _c(
                      "b-button",
                      {
                        staticClass: "no-border",
                        on: { click: _vm.removeMessage },
                      },
                      [
                        _c("i", { staticClass: "fal fa-trash-alt" }),
                        _c("span", { staticClass: "hidden-mobile" }, [
                          _vm._v(" " + _vm._s(_vm.$t("REMOVE"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      !_vm.isConversation
        ? _c("LoaLevel", { attrs: { loa_level: _vm.loa_error } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }